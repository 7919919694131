@layer base {
  @font-face {
    font-family: 'Big Caslon';
    src: local('Big Caslon'),
      url(../public/fonts/Big-Caslon-Regular.otf) format('opentype'),
      url(../public/fonts/Big-Caslon-Regular.woff) format('woff');
  }
  @font-face {
    font-family: 'Soehne';
    src: local('Soehne'), url(../public/fonts/Söhne-Buch.otf) format('opentype'),
      url(../public/fonts/Söhne-Buch.woff) format('woff');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Soehne';
    src: local('Soehne'), url(../public/fonts/Söhne-Halbfett.otf) format('otf'),
      url(../public/fonts/Söhne-Halbfett.woff) format('woff');
    font-weight: 600;
  }
}

*,
body {
  margin: 0;
  padding: 0;
  font-family: 'Soehne', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 100%;
}
body {
  font-size: 0.75rem;
}
a {
  outline: none;
}
*:focus {
  outline: none;
}
button {
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a > p {
  font-weight: 600;
  letter-spacing: 0.1em;
}

.loading-container {
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;
  justify-content: space-between;
  gap: 10%;
  background-color: #4c4581;
  padding: 10px;
  width: 100%;
}

.loading,
.loading-1,
.loading-3,
.loading-4 {
  background-color: #5b548e;
  background-image: linear-gradient(
    to left,
    #c2c2c2,
    #a4a1b2,
    #8781a2,
    #696292,
    #4c4581
  );
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  opacity: 0.1;
}
.loading-1 {
  height: 50px;
}
.loading-3 {
  height: 20px;
  margin-bottom: 10px;
}
.loading-4 {
  max-width: 300px;
}
.shape2 {
  height: 10px;
}
.reverse-direction .loading {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
