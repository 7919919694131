.control-arrow {
  width: 23px !important;
  height: 23px !important;
  opacity: 1 !important;

  @media only screen and (max-width: 800px) {
    display: none;
  }

  &.control-prev {
    left: 8.4rem !important;
    top: 86.6% !important;
  }
  &.control-next {
    left: 10.5rem !important;
    top: 80.1% !important;
  }
  &:hover {
    opacity: 0.5 !important;
    background-color: transparent !important;
    filter: invert(29%) sepia(13%) saturate(2201%) hue-rotate(207deg)
      brightness(91%) contrast(89%);
  }
}

.carousel .control-prev.control-arrow:before {
  content: none;
  border: none;
}

.carousel .control-next.control-arrow:before {
  content: none;
  border: none;
}
